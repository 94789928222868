import React from "react";
import {FaEye, FaEyeSlash, FaFile, FaPencilAlt} from "react-icons/fa";
import { formatNumberToDisplayWithCommas } from "../../../common/shared";

const PurchaseOrdersTable = (
    setSelectedRow, setApproveModal, setDisapproveModal, setSelectedFilesRow, setFormModalFiles
) => {
    return [
        {
            Header: 'Client',
            accessor: 'client_name',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'PO Number',
            accessor: 'po_number',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <a
                    href={`/commercial/po_tracker/edit_purchase_order/${row.id}`}
                        className={'font-weight-bold'}
                        rel="noopener noreferrer"
                        target="_blank">
                        {cell.value}
                    </a>
                );
            }
        },
        {
            Header: 'Original PO Amount',
            accessor: 'original_po_amount',
            Cell: (cell) => {
                return `$${formatNumberToDisplayWithCommas(cell.value)}`
            }
        },
        {
            Header: 'Total Adjusted PO Amount',
            accessor: 'total_adjusted_po',
            Cell: (cell) => {
                return `$${formatNumberToDisplayWithCommas(cell.value)}`
            }
        },
        {
            Header: 'Remaining PO Funds',
            accessor: 'remaining_po_funds',
            Cell: (cell) => {
                return `$${formatNumberToDisplayWithCommas(cell.value)}`
            }
        },
        {
            Header: 'AP Contact',
            accessor: 'ap_contact_name',
        },
        {
            Header: 'Notes',
            accessor: 'notes',
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <a
                            href={`/commercial/po_tracker/edit_purchase_order/${row.id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FaPencilAlt
                                color='#A51E37'
                                title="Edit Purchase Order"
                            />
                        </a>
                        {(row.status === 1) &&
                            <>
                                {' '}
                                <FaEyeSlash
                                    className="actions"
                                    title="Deactivate Purchase Order"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to deactivate <b>{row.po_number}</b>?</p>;
                                        setSelectedRow(row);
                                        setDisapproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                        {(row.status === 0) &&
                            <>
                                {' '}
                                <FaEye
                                    className="actions"
                                    title="Reactivate Purchase Order"
                                    onClick={() => {
                                        const msg = <p>Are you sure you want to reactivate <b>{row.po_number}</b>?</p>;
                                        setSelectedRow(row);
                                        setApproveModal({
                                            message: msg,
                                            isOpen: true,
                                        });
                                    }}
                                />
                            </>
                        }
                        <FaFile
                            className="actions ml-1"
                            title="Files"
                            onClick={() => {
                                setSelectedFilesRow(row);
                                setFormModalFiles({isOpen: true});
                            }}
                        />
                    </>
                );
            }
        }
    ];
}

export default PurchaseOrdersTable;



