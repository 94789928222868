import React, {useState} from 'react';
import Table from "../../../common/table/table";
import PurchaseOrdersTable from "./PurchaseOrdersTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import { removeObjFromArray, replacePartialObjFromArray } from '../../../../utils/arrayHelpers';

/**
 * PurchaseOrdersBody component
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const PurchaseOrdersBody = (prop) => {
    const {filters, tableData, setTableData, setSelectedFilesRow, setFormModalFiles} = prop;
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [selectedRow, setSelectedRow] = useState({});

    const ApproveHandler = () => {
        apiClient
            .post('activatePurchaseOrder', {'id': selectedRow.id})
            .then(response => {
                const newTableData = filters.isFilteredByStatus ?
                    removeObjFromArray(tableData, "id", selectedRow.id) :
                    replacePartialObjFromArray(tableData, 'id', selectedRow.id, { status: 1 });
                    
                setTableData(newTableData);

                toast.success(response.data.message);
            })
            .then(function () {
                //close modal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('deactivatePurchaseOrder', {'id': selectedRow.id})
            .then(response => {
                const newTableData = filters.isFilteredByStatus ?
                    removeObjFromArray(tableData, "id", selectedRow.id) :
                    replacePartialObjFromArray(tableData, 'id', selectedRow.id, { status: 0 });
                    
                setTableData(newTableData);

                toast.success(response.data.message);
            })
            .then(function () {
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <Table
                columns={
                    PurchaseOrdersTable(
                        setSelectedRow, setApproveModal, setDisapproveModal, setSelectedFilesRow, setFormModalFiles
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'PurchaseOrders'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Reactivate Purchase Order"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Deactivate Purchase Order"
                onConfirm={DisapproveHandler}
            />
        </div>
    );
}

export default PurchaseOrdersBody;
