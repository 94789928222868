import {AccordionItem} from 'react-light-accordion';
import RevenuePerShiftChart from './charts/revenuePerShiftChart';
import FootagePerShiftChart from './charts/footagePerShiftChart';
import NonBillableChart from './charts/nonBillableChart/nonBillableChart';
import DrillingChart from './charts/drillingChart';
import DowntimeChart from './charts/downtimeChart/downtimeChart';
import ShiftUtilizationChart from './charts/shiftUtilization';
import LaborPerShiftChart from './charts/laborPerShiftChart/laborPerShiftChart';

const OperationalDashboardAccordion = ({title, data, withSummaryTooltips = false}) => {

    return (
        <AccordionItem className="card-header bg-primary" title={title}>
            <div className="p-2">
                <div className="d-flex overflow-auto">
                    {
                        data.revenue_per_shift && (
                            <div style={{minWidth: '800px'}}>
                                <RevenuePerShiftChart chartData={data.revenue_per_shift} />
                            </div>
                        )}
                    {
                        data.footage_per_shift && (
                            <div style={{minWidth: '800px'}}>
                                <FootagePerShiftChart chartData={data.footage_per_shift} />
                            </div>
                        )
                    }
                    {
                        data.non_billable && (
                            <div style={{minWidth: '800px'}}>
                                <NonBillableChart chartData={data.non_billable} withSummaryTooltip={withSummaryTooltips} />
                            </div>
                        )
                    }
                    {
                        data.drilling && (
                            <div style={{minWidth: '800px'}}>
                                <DrillingChart chartData={data.drilling} />
                            </div>
                        )
                    }
                    {
                        data.labor_per_shift && (
                            <div style={{minWidth: '800px'}}>
                                <LaborPerShiftChart chartData={data.labor_per_shift} withSummaryTooltip={withSummaryTooltips} />
                            </div>
                        )
                    }
                    {
                        data.downtime && (
                            <div style={{minWidth: '800px'}}>
                                <DowntimeChart chartData={data.downtime} withSummaryTooltip={withSummaryTooltips} />
                            </div>
                        )
                    }
                    {
                        data.shift_utilization && (
                            <div style={{minWidth: '800px'}}>
                                <ShiftUtilizationChart chartData={data.shift_utilization} />
                            </div>
                        )
                    }
                </div>
            </div>
        </AccordionItem>
    )
}

export default OperationalDashboardAccordion;
