import { formatNumberToDisplayWithCommas } from "../../../common/shared";

export const tableColumns = [
    {
        Header: 'Client',
        accessor: 'client_name',
        width: 300,
    },
    {
        Header: 'Rig',
        accessor: 'rig_name',
        width: 300,
    },
    {
        Header: 'Billed %',
        accessor: 'percent_billed',
    },
    {
        Header: 'Revenue',
        accessor: 'revenue',
        Cell: (cell) => `$${formatNumberToDisplayWithCommas(cell.value)}`,
    },
    {
        Header: 'Avg Rev Shift',
        accessor: 'avg_rev_shift',
        Cell: (cell) => `$${formatNumberToDisplayWithCommas(cell.value)}`,
    },
    {
        Header: 'FT/Shift',
        accessor: 'footage_per_shift',
    },
];
