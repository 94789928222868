import React from 'react';
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component
 * @returns {JSX.Element}
 * @constructor
 */
const PurchaseOrdersFilters = (prop) => {
    const {setFilters, setTableData} = prop;
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post('getPurchaseOrders', {status: data.status !== '' ? Number(data.status) : null})
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                setTableData([]);
            }).then(function () {
            updateStateValue(setFilters,'isLoading', false);
            updateStateValue(setFilters, 'isFilteredByStatus', Boolean(data.status));
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={(e) => {updateStateValue(setFilters, e.target.id, e.target.value)}}
                    options={[{value: 1, label: "Active"}, {value: 0, label: "Inactive"}]}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default PurchaseOrdersFilters;
