import Accordion from 'react-light-accordion';
import OperationalDashboardAccordionsByDiscipline from './operationalDashboardAccordionsByDiscipline';
import { useEffect } from 'react';
import * as Shared from "../../../common/shared";

const OperationalDashboardBody = ({dashboardData}) => {

    useEffect(() => {
        Shared.toggleAllAccordionItems();
    }, [dashboardData])

    if (dashboardData === null) {
        return null;
    }

    return (
        <div className="card" id="accordion_card">
            <div className="card-body">
                <div className="default-according panel-accordion" id="accordionclose">
                    <Accordion atomic="false">
                        {
                            Object.keys(dashboardData).map(key => (
                                <OperationalDashboardAccordionsByDiscipline
                                    key={key}
                                    disciplineKey={key}
                                    disciplineData={dashboardData[key]}
                                />
                            ))
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default OperationalDashboardBody;