import { downtimeActivitiesTooltipTableColumns } from "./downtimeActivitiesTooltipTableColumns";
import TableReport from "../../../../../common/table/table";
import {ChartTooltip} from '../../../../../../components/common/chartTooltip';

export const DowntimeActivitiesTooltip = ({data}) => {
    const {x, y, activitiesData} = data;

    const hasData = activitiesData.length > 0;

    return (
        <ChartTooltip x={x} y={y}>
            {
                hasData ? (
                    <TableReport
                        columns={downtimeActivitiesTooltipTableColumns}
                        data={activitiesData}
                    />
                ) : (
                    'No data'
                )
            }
        </ChartTooltip>
    )
}