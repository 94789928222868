import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Breadcrumb from '../../common/breadcrumb';
import apiClient from '../../../utils/apiClient';
import Select from '../../forms/form-control/daily/Select';
import FormInputText from '../../forms/form-control/FormInputText';
import FormInputTextArea from '../../forms/form-control/FormInputTextArea';
import FormInputFile from '../../forms/form-control/FormInputFile';
import { toast } from 'react-toastify';

const CreatePurchaseOrder = () => {
    const { handleSubmit, register, errors, reset } = useForm();
    const [clients, setClients] = useState([]);

    useEffect(() => {
        apiClient
            .get('getActiveClientsList')
            .then(response => {
                if (Object.keys(response.data).length === 0) {
                    setClients([]);
                    return;
                }
                setClients(response.data.activeClientsList.map(client => ({label: client.client_name, value: client.id})));
            })
            .catch(function () {
                setClients([]);
            })
    }, []);

    const onSubmit = (data) => {
        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'files':
                    for (const file of data[key]) {
                        formData.append('files[]', file, file.name);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        apiClient
            .post('createPOTracker', formData)
            .then(() => {
                toast.success("Purchase order has been created.");
                reset();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>
            <Breadcrumb title="Create Purchase Order" parent="Commercial" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="create_job_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row mb-3">
                                        <Select
                                            label="Client"
                                            id="client_id"
                                            name="client_id"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={clients}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="ap_contact_name"
                                            label="AP Contact Name"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="project"
                                            label="Project"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormInputText
                                            id="po_number"
                                            label="PO Number"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="original_po_amount"
                                            label="Original PO amount"
                                            type="number"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required", max: {value: 99999999, message: "Max value is 99999999"}})}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="notes"
                                            rows={3}
                                            label="Notes"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                                maxLength: {
                                                    value:256,
                                                    message: "Maximum length is 256"
                                                }})}
                                            errors={errors}
                                            max={256}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Files</h4>
                                    <div className="form-row">
                                        <FormInputFile
                                            id="files"
                                            className="col-md-12"
                                            register={register}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreatePurchaseOrder;
