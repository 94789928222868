import { activitiesTooltipTableColumns } from "./activitiesTooltipTableColumns";
import TableReport from "../../../../../common/table/table";
import { ChartTooltip } from "../../../../../common/chartTooltip";

export const ActivitiesTooltip = ({data}) => {
    const {x, y, activitiesData} = data;

    const hasData = activitiesData.length > 0;

    return (
        <ChartTooltip x={x} y={y}>
            {
                hasData ? (
                    <TableReport
                        columns={activitiesTooltipTableColumns}
                        data={activitiesData}
                    />
                ) : (
                    'No data'
                )
            }
        </ChartTooltip>
    )
}