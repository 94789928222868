import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import PurchaseOrdersBody from "./PurchaseOrdersBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import PurchaseOrdersFilters from "./PurchaseOrdersFilters";
import FileManager from "../../../common/file_manager/fileManager";

const PurchaseOrders = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedFilesRow, setSelectedFilesRow] = useState({});
    const [formModalFiles, setFormModalFiles] = useState({isOpen: false});
    const [filters, setFilters] = useState({
        status: '',
        isLoading: false,
        isSubmitted: false,
        isFilteredByStatus: false,
    });

    return (
        <>
            <Breadcrumb title="Purchase Orders" parent="Operations"/>
            <PurchaseOrdersFilters {...{filters, setFilters, setTableData}} />
            <PurchaseOrdersBody {...{filters, setFilters, tableData, setTableData, setSelectedFilesRow, setFormModalFiles}} />
            <FileManager
                fileableId={selectedFilesRow.id}
                fileableType ="App\Models\POTracker"
                setFileableId={setSelectedFilesRow}
                modalTitle="Purchase Order Files"
                modal={formModalFiles}
                setModal={setFormModalFiles}
                allowUpload={false}
                allowShareFiles={true}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default PurchaseOrders;
