import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputDate from "../../forms/form-control/FormInputDate";
import CreateInvoiceTableDailies from "./createInvoiceTableDailies";
import CreateInvoiceTableCharges from "./createInvoiceTableCharges";
import {updateStateValue} from "../../../utils/stateHelpers";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";

/**
 * Create invoice form component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateInvoiceForm = (prop) => {
    const {filters, setFilters} = prop;
    const {register, control, handleSubmit, setValue, getValues, reset, errors} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [invoiceStatuses, setInvoiceStatuses] = useState([]);
    const [clients, setClients] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [totalDailies, setTotalDailies] = useState(0);
    const [totalInvoiceCharges, setTotalInvoiceCharges] = useState(0);
    const [dailies, setDailies] = useState([]);
    const [poNumbers, setPoNumbers] = useState([]);

    // get lists
    useEffect(() => {
        //set current date for date select
        document.getElementById('date').valueAsDate = new Date();

        // disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
            });

        // invoices statuses
        apiClient
            .get('getInvoicesStatuses')
            .then(response => {
                setInvoiceStatuses(response.data);
            });
    }, [setDisciplines, setInvoiceStatuses]);

    const fetchDailies = useCallback(() => {
        if (filters.job_id) {
            apiClient
                .post('getActiveDailiesByJob', {'job_id': filters.job_id})
                .then(response => {
                    if (!isEmpty(response.data)) {
                        setDailies(response.data);

                        let totalRevenue = 0;

                        response.data.forEach((row) => {
                            totalRevenue += Number(row.revenue);
                        });

                        setTotalDailies(totalRevenue);
                    } else {
                        setDailies([]);
                    }
                })
        } else {
            setDailies([]);
        }
    }, [filters.job_id])

    useEffect(() => {
        fetchDailies();
    }, [fetchDailies]);

    const onSelectDiscipline = (e) => {
        const discipline_id = e.target.value;
        updateStateValue(setFilters, 'job_id', '');

        if (discipline_id) {
            // active clients
            apiClient
                .post('getActiveClientsByDiscipline', {'discipline_id': discipline_id})
                .then(response => {
                    setClients(
                        response.data.map(
                            ({id, client_name}) => ({id: id, name: client_name})
                        )
                    );
                });
            setValue('client_id', '');
            setValue('job_id', '');
        } else {
            setClients([]);
            setJobs([]);
            setPoNumbers([]);
        }
    }

    const onSelectClient = (e) => {
        const client_id = e.target.value;
        const discipline_id = getValues('discipline_id');
        updateStateValue(setFilters, 'job_id', '');
        updateStateValue(setFilters, 'po_number', '');

        if (client_id) {
            // active jobs
            apiClient
                .post('getActiveJobsByDisciplineAndClient',
                    {'discipline_id': discipline_id, 'client_id': client_id}
                )
                .then(response => {
                    setJobs(
                        response.data.map(
                            ({id, job_number}) => ({id: id, name: job_number})
                        )
                    );
                });
            
            apiClient
                .post('getPONumbersByClientId', { client_id: Number(client_id) })
                .then(response => {
                    setPoNumbers(
                        response.data.map(
                            ({po_number}) => ({id: po_number, name: po_number}),
                        )
                    )
                })

            setValue('job_id', '');
            setValue('po_number', '');
        } else {
            setJobs([]);
            setPoNumbers([]);
        }
    }

    const onSelectJob = (e) => {
        const {id, value} = e.target;
        updateStateValue(setFilters, id, value);
    }

    const onSave = (data) => {
        const formData = new FormData();
        for (const key in data) {
            switch (key) {
                case 'invoice_file':
                    for (const file of data[key]) {
                        formData.append('invoice_file[]', file, file.name);
                    }
                    break;
                case 'dailies':
                    for (const obj of data[key]) {
                        formData.append('dailies[].id', obj.id);
                    }
                    break;
                case 'revenue':
                    for (const obj of data[key]) {
                        formData.append('revenue[].revenue', obj.revenue);
                    }
                    break;
                case 'invoice_charges':
                    for (const obj of data[key]) {
                        formData.append('invoice_charges[].id', obj.id);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        updateStateValue(setFilters, 'isLoading', true);
        apiClient
            .post('createInvoice', formData)
            .then(response => {
                toast.success(response.data.message);
                reset();
                //set current date for date select
                document.getElementById('date').valueAsDate = new Date();
            }).catch(function (e) {
                fetchDailies();
            })
            .then(function () {
                updateStateValue(setFilters, 'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSave)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={onSelectDiscipline}
                />
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={clients}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={onSelectClient}
                />
                <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={jobs}
                    register={register({required: "required"})}
                    errors={errors}
                    onChange={onSelectJob}
                />
            </div>
            <div className="form-row row s-gy m-t-10">
                <FormInputText
                    id="invoice_number"
                    label="Invoice #"
                    type="text"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSelect
                    id="invoice_status"
                    label="Status"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={invoiceStatuses}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date"
                    label="Date"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    register={register({required: "required"})}
                    errors={errors}
                    disabled={true}
                />
                <FormSelect
                    id="po_number"
                    label="PO #"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={poNumbers}
                    register={register({required: false})}
                    errors={errors}
                />
            </div>
            {!isEmpty(getValues().job_id) &&
                <>
                    <div className="form-row m-t-10">
                        <h5>Dailies</h5>
                    </div>
                    <div className="form-row m-t-10">
                        <CreateInvoiceTableDailies {...{
                            register,
                            control,
                            errors,
                            setTotalDailies,
                            totalDailies,
                            totalInvoiceCharges,
                            dailies
                        }} />
                    </div>
                    <div className="form-row m-t-10">
                        <h5>Invoice Charges</h5>
                    </div>
                    <div className="form-row m-t-10">
                        <CreateInvoiceTableCharges {...{
                            setFilters,
                            filters,
                            register,
                            control,
                            errors,
                            setTotalInvoiceCharges
                        }} />
                    </div>
                    <div className="form-row m-t-10">
                        <span className="font-weight-bold">Grand Total : &nbsp;</span>
                        <b className="red">
                            {
                                (totalDailies + totalInvoiceCharges).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                })
                            }
                        </b>
                    </div>
                </>
            }
            {/*<div className="form-row">*/}
            {/*    <FormInputFile*/}
            {/*        id="invoice_file"*/}
            {/*        label="Invoice files"*/}
            {/*        className="col-md-8 mb-3"*/}
            {/*        register={register}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="form-row">
                <FormSubmitButton
                    className="col-md-1"
                    label="Create"
                />
            </div>
        </form>
    );
}

export default CreateInvoiceForm;
