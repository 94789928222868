import styled from "styled-components";

export const ChartTooltip = styled.div`
    position: absolute;
    left: ${props => `${props.x}px`};
    top: ${props => `${props.y}px`};
    border: 3px solid black;
    transform: translate(-50%, -100%);
    background-color: white;
    z-index: 10000;
    width: 800px;
    max-width: 100vw;
`;
