import React, { Fragment } from 'react';

const ChartContainer = ({title, summaryItems, children}) => {

    return (
        <div className="p-0 m-0">
            <div className="row">
                <div className="col-3 d-flex flex-column align-items-center justify-content-center p-4">
                    <h4 className="font-weight-bold h6 mb-4">{title}</h4>
                    {summaryItems.map(item => (
                        <Fragment key={item.label}>
                            <p className="mb-0 p-0 font-weight-bold h4">{item.value}</p>
                            <p className="mb-4 p-0 font-italic">{item.label}</p>
                        </Fragment>
                    ))}
                </div>
                <div className="col-9 pl-0">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ChartContainer;
