/**
 * ChartJS plugin which allows to hide tooltips for specific datasets based on their indexes.
 * Provide array of indexes as as `hideForDatasetIndexes` property of tooltip plugin options.
 */
export const hideTooltipForDatasetsPlugin = {
    id: "hide-tooltip-for-datasets",
    beforeTooltipDraw: function (chartInstance, args, options) {
        args.cancelable = true;
        if (chartInstance.config._config.options?.plugins?.tooltip?.hideForDatasetIndexes) {
            const datasetIndexesToHide =
                chartInstance.config._config.options.plugins.tooltip
                    .hideForDatasetIndexes;

            const { tooltip } = args;

            if (
                tooltip.dataPoints.length === 1 &&
                datasetIndexesToHide.includes(tooltip.dataPoints[0].datasetIndex)
            ) {
                return false;
            }
        }
    },
};
