import React, {useState, useCallback, useEffect} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import FormInputText from "../../forms/form-control/FormInputText";
import FormInputDate from "../../forms/form-control/FormInputDate";
import EditInvoiceTableDailies from "./editInvoiceTableDailies";
import EditInvoiceTableCharges from "./editInvoiceTableCharges";
import {updateStateValue} from "../../../utils/stateHelpers";
import {toast} from "react-toastify";
import {isEmpty, isNull} from "lodash";

/**
 * EditInvoiceForm
 * @returns {JSX.Element}
 * @constructor
 */
const EditInvoiceForm = (prop) => {
    const {register, control, handleSubmit, errors} = useForm();
    const {invoiceId, filters, setFilters, invoiceStatuses, jobId} = prop;
    const [totalDailies, setTotalDailies] = useState(0);
    const [totalInvoiceCharges, setTotalInvoiceCharges] = useState(0);
    const [dailies, setDailies] = useState([])
    const [poNumbers, setPoNumbers] = useState(null);

    const fetchDailies = useCallback(() => {
        apiClient
            .post('getActiveDailiesByInvoice', {'id': invoiceId, 'job_id': jobId})
            .then(response => {
                if (!isEmpty(response.data.dailies)) {
                    setDailies(response.data.dailies);

                    let totalRevenue = 0;

                    response.data.dailies.forEach((row) => {
                        totalRevenue += Number(row.revenue);
                    });

                    setTotalDailies(totalRevenue);
                } else {
                    setDailies([]);
                }
            });
    }, [invoiceId, jobId])
    
    useEffect(() => {
        if (filters.client_id) {
            apiClient
                .post('getPONumbersByClientId', { client_id: Number(filters.client_id) })
                .then(response => {
                    setPoNumbers(
                        response.data.map(
                            ({po_number}) => ({id: po_number, name: po_number}),
                        )
                    )
                })
        }
    }, [filters.client_id])

    useEffect(() => {
        fetchDailies();
    }, [fetchDailies])
    
    if (isEmpty(filters)) {
        return null;
    }

    if (isEmpty(invoiceStatuses)) {
        return null;
    }

    if (isNull(poNumbers)) {
        return null;
    }

    const onSubmit = (data) => {
        console.log(data)
        const formData = new FormData();
        for (const key in data)
        {
            switch (key) {
                case 'invoice_file':
                    for (const file of data[key]) {
                        formData.append('invoice_file[]', file, file.name);
                    }
                    break;
                case 'dailies':
                    for (const obj of data[key]) {
                        formData.append('dailies[].id', obj.id);
                    }
                    break;
                case 'revenue':
                    for (const obj of data[key]) {
                        formData.append('revenue[].revenue', obj.revenue);
                    }
                    break;
                case 'invoice_charges':
                    for (const obj of data[key]) {
                        formData.append('invoice_charges[].id', obj.id);
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        updateStateValue(setFilters, 'isLoading', true);

        apiClient
            .post('editInvoice', formData)
            .then(response => {
                toast.success(response.data.message);
                // reset();
            }).catch(function (e) {
                fetchDailies();
            })
            .then(function () {
                updateStateValue(setFilters, 'isLoading', false);
            });
    }

    return (
        <form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                <FormInputText
                    id="id"
                    type="hidden"
                    value={filters.invoice_id}
                    register={register({required: "required"})}
                />
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-md-q mb-3"
                    value={filters.discipline_id}
                    options={
                        isNull(filters.discipline)
                            ?
                                []
                            :
                                [{
                                    id: filters.discipline.id,
                                    name: filters.discipline.name
                                }]
                    }
                    disabled={true}
                />
                <FormInputText
                    id="invoice_number"
                    label="Invoice #"
                    type="text"
                    className="col-md-q mb-3"
                    value={filters.invoice_number}
                    disabled={true}
                />
                <FormSelect
                    id="invoice_status"
                    label="Status"
                    className="col-md-q mb-3"
                    value={filters.invoice_status}
                    options={invoiceStatuses}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date"
                    label="Date"
                    className="col-md-q"
                    value={filters.date}
                    register={register({required: "required"})}
                />
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-md-q mb-3"
                    value={filters.client_id}
                    options={
                        isNull(filters.client)
                            ?
                                []
                            :
                                [{
                                    id: filters.client.id,
                                    name: filters.client.client_name
                                }]
                    }
                    disabled={true}
                />
                <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-md-q mb-3"
                    value={filters.job_id}
                    options={
                        isNull(filters.job)
                            ?
                                []
                            :
                                [{
                                    id: filters.job.id,
                                    name: filters.job.job_number
                                }]
                    }
                    disabled={true}
                />
                <FormSelect
                    id="po_number"
                    label="PO #"
                    className="col-md-q mb-3"
                    options={poNumbers}
                    register={register({required: false})}
                    errors={errors}
                    value={filters.po_number}
                />
            </div>
            <div className="form-row m-t-10">
                <h5>Dailies</h5>
            </div>
            <div className="form-row">
                <EditInvoiceTableDailies {...{invoiceId, register, control, jobId, setTotalDailies, totalDailies, totalInvoiceCharges, dailies}} />
            </div>
            <div className="form-row m-t-10">
                <h5>Invoice Charges</h5>
            </div>
            <div className="form-row m-t-10">
                <EditInvoiceTableCharges {...{invoiceId, register, control, setTotalInvoiceCharges, jobId}} />
            </div>
            <div className="form-row m-t-10">
                <span className="font-weight-bold">Grand Total : &nbsp;</span>
                <b className="red">
                    {
                        (totalDailies + totalInvoiceCharges).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        })
                    }
                </b>
            </div>
            <div className="form-row">
                <FormSubmitButton
                    className="col-md-q"
                    label="Save"
                />
            </div>
        </form>
    );
}

export default EditInvoiceForm;
