import React, { useState } from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from '../../../common/loadingIndicator';
import OperationalDashboardFilters from './operationalDashboardFilters';
import OperationalDashboardBody from './operationDashboardBody';

/**
 * OperationalDashboard Report
 * @returns {JSX.Element}
 * @constructor
 */
const OperationalDashboard = () => {

    const [dashboardData, setDashboardData] = useState(null);
    const [reportFilters, setReportFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    const reportFilterProps = {
        setReportFilters,
        setDashboardData,
    };

    const reportBodyProps = {
        dashboardData,
    };

    return (
        <>
            <Breadcrumb title="Operational Dashboard" parent="Toolbox"/>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
            <OperationalDashboardFilters {...reportFilterProps} />
            <OperationalDashboardBody {...reportBodyProps} />
        </>
    );
}

export default OperationalDashboard;
