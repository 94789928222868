import OperationalDashboardAccordion from "./operationalDashboardAccordion";

const DISCIPLINE_KEY_TO_READABLE = {
    rcx: 'RCX',
    rotary: 'Rotary',
    surface_core: 'Surface Core',
    underground_core: ' Underground Core',
}

const OperationalDashboardAccordionsByDiscipline = ({disciplineData, disciplineKey}) => {
    const rigData = disciplineData.summary_by_rigs;
    const hasRigData = !Array.isArray(rigData);

    return (
        <>
            <OperationalDashboardAccordion
                title={DISCIPLINE_KEY_TO_READABLE[disciplineKey]}
                data={disciplineData.summary}
            />
            {
                hasRigData && Object.keys(rigData).map((rig) => (
                    <OperationalDashboardAccordion
                        key={rig}
                        title={rig}
                        data={rigData[rig]}
                        withSummaryTooltips
                    />
                ))
            }
        </>
    )
}

export default OperationalDashboardAccordionsByDiscipline;