import React, {useMemo, useState} from 'react';
import getExportFileBlob from "../../../../utils/exportTable";
import {useTable, useSortBy, useGlobalFilter} from 'react-table'
import {useExportData} from "react-table-plugins";
import TableExportButtons from "./tableExportButtons";
import TableGlobalFilter from "./tableGlobalFilter";
import {isEmpty, isNull} from "lodash";

/**
 * Component to build a react-table with export options
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TableReport = (prop) => {
    let {
        columns,
        data,
        getExportFileName,
        exportOptions = null,
        withGlobalSearch = false,
        isSortDisabled = false,
        lastRowsToExcludeFromSort = 0,
    } = prop;

    //define number of columns based on columns property
    let numCols = 0;
    if (!isEmpty(columns)) {
        numCols = (columns[0].hasOwnProperty('columns')) ? columns[0].columns.length : columns.length;
    }

    //memo columns
    columns = useMemo(() => columns, [columns]);

    // set Table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        exportData,
    } = useTable(
        {
            columns,
            data,
            getExportFileBlob,
            getExportFileName,
            disableSortBy: isSortDisabled,
        },
        useGlobalFilter,
        useSortBy,
        useExportData
    );

    const [hoveredRow, setHoveredRow] = useState()

    const maxRowIndex = rows.length - 1;
    const rowsExcludedFromSort = rows
        .filter(row => row.index + lastRowsToExcludeFromSort > maxRowIndex)
        .sort((row1, row2) => row1.index > row2.index ? 1 : -1);
        
    const rowsWithoutExcludedFromSort = rows
        .filter(row => !rowsExcludedFromSort.some(lastRow => lastRow.index === row.index));

    return (
        <div className="s-table-standard-container">
            {withGlobalSearch && <TableGlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter}/>}
            <div className="s-table-standard">
                <table className="table table-striped" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {/* can sort indicator */}
                                    <span className={column.canSort ? 'table-sorted-column' : ''}>{column.render('Header')}</span>
                                    {/* sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' ▼'
                                                : ' ▲'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rowsWithoutExcludedFromSort.map((row, idx) => {
                        prepareRow(row)
                        return (
                            <tr
                                {...row.getRowProps()}
                                onMouseOver={() => setHoveredRow(idx)}
                                onMouseOut={() => setHoveredRow(null)}
                                style={{ backgroundColor: idx === hoveredRow ? '#00000020' : idx%2 ? 'transparent' : '#f5f7fa80' }}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    {rowsExcludedFromSort.map((row, idx) => {
                        prepareRow(row)
                        return (
                            <tr
                                {...row.getRowProps()}
                                onMouseOver={() => setHoveredRow(idx)}
                                onMouseOut={() => setHoveredRow(null)}
                                style={{ backgroundColor: idx === hoveredRow ? '#00000020' : idx%2 ? 'transparent' : '#f5f7fa80' }}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                    {!isNull(exportOptions) &&
                    <tfoot>
                        <TableExportButtons numCols={numCols} exportData={exportData} exportOptions={exportOptions}/>
                    </tfoot>
                    }
                </table>
            </div>
           
        </div>
    );
};

export default TableReport;
