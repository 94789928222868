import {ChartTooltip} from '../../../../../common/chartTooltip';

export const ShiftReportTooltip = ({data}) => {
    const {x, y, detailData} = data;

    return (
        <ChartTooltip x={x} y={y}>
            <div className="w-100 p-1">
                <div className="border-bottom mt-2 pb-2">
                    <div className="row">
                        <div className="col-3">
                            <span className="text-nowrap">Day Shift</span>
                        </div>
                        <div className="col-4 d-flex">
                            <span className="font-weight-bold text-nowrap mr-2">
                                Location:
                            </span>
                            <span className="text-nowrap">
                                {detailData?.DayShiftDetails?.location}
                            </span>
                        </div>
                        <div className="col-4 d-flex">
                            {
                                detailData?.DayShiftDetails?.revenue != null && (
                                    <>
                                        <span className="font-weight-bold text-nowrap mr-2">
                                            Shift Total:
                                        </span>
                                        <span className="text-nowrap">
                                            {detailData?.DayShiftDetails?.revenue}
                                        </span>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <span className="font-weight-bold">Name</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Title</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Hrs</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Per Diem</span>
                        </div>
                    </div>
                    {detailData?.DayShiftDetails?.shift_details?.map((item) => {
                        return (
                            <div className="row mt-2" key={item.name}>
                                <div className="col-3 text-nowrap">{item.name}</div>
                                <div className="col-3 text-nowrap">
                                    {item.group_name}
                                </div>
                                <div className="col-3 text-nowrap">
                                    {item.hours}
                                </div>
                                <div className="col-3 text-nowrap">
                                    {item.per_diem}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="mt-2">
                    <div className="row">
                        <div className="col-3">
                            <span className="text-nowrap">Night Shift</span>
                        </div>
                        <div className="col-4 d-flex">
                            <span className="font-weight-bold text-nowrap mr-2">
                                Location:
                            </span>
                            <span className="text-nowrap">
                                {detailData?.NightShiftDetails?.location}
                            </span>
                        </div>
                        <div className="col-4 d-flex">
                            {
                                detailData?.NightShiftDetails?.revenue != null && (
                                    <>
                                        <span className="font-weight-bold text-nowrap mr-2">
                                            Shift Total:
                                        </span>
                                        <span className="text-nowrap">
                                            {detailData?.NightShiftDetails?.revenue}
                                        </span>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <span className="font-weight-bold">Name</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Title</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Hrs</span>
                        </div>
                        <div className="col-3">
                            <span className="font-weight-bold">Per Diem</span>
                        </div>
                    </div>
                    {detailData?.NightShiftDetails?.shift_details?.map((item) => {
                        return (
                            <div className="row mt-2" key={item.name}>
                                <div className="col-3 text-nowrap">{item.name}</div>
                                <div className="col-3 text-nowrap">
                                    {item.group_name}
                                </div>
                                <div className="col-3 text-nowrap">
                                    {item.hours}
                                </div>
                                <div className="col-3 text-nowrap">
                                    {item.per_diem}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </ChartTooltip>
    )
}