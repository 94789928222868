import React, {useEffect, useState} from 'react';
import apiClient from "../../../../utils/apiClient";
import TableReport from "../../../common/table/table";
import {isEmpty} from "lodash";
import EditPurchaseOrderFilesTableColumns from "./editPurchaseOrderFilesTableColumns";

/**
 * table for display/download files
 * @returns {JSX.Element}
 * @constructor
 */
const EditPurchaseOrderFilesTable = (props) => {
    const {fileableId, fileableType, isUpdated, setIsUpdated} = props;
    const [tableData, setTableData] = useState([]);

    // load list of files
    useEffect(() => {
        if (fileableId || isUpdated) {
            apiClient
                .post('getFiles',
                    {fileable_id: fileableId, fileable_type: fileableType})
                .then(response => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                    setIsUpdated(false);
                });
        }
    }, [fileableId, fileableType, isUpdated, setIsUpdated]);

    // download handler
    const onDownload = (row) => {
        //get pdf export file and open it in a new window
        apiClient('downloadFile', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: {id: row.id}
        })
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', row.original_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(function () {});
    }

    return (
        <TableReport
            columns={EditPurchaseOrderFilesTableColumns(
                { onDownload, allowShareFiles:false}
            )}
            data={tableData}
        />
    );
}

export default React.memo(EditPurchaseOrderFilesTable);