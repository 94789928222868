export const downtimeActivitiesTooltipTableColumns = [
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Shift',
        accessor: 'shift',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: 'Hours',
        accessor: 'units',
    },
    {
        Header: 'Category',
        accessor: 'revenue_group',
    },
];
