import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from '../../../../utils/apiClient';
import FormInputText from '../../../forms/form-control/FormInputText';
import FormInputTextArea from '../../../forms/form-control/FormInputTextArea';
import FormInputFile from '../../../forms/form-control/FormInputFile';
import { toast } from 'react-toastify';
import FileManager from './editPurchaseOrderFilesTable';
import FormSelect from '../../../forms/form-control/FormSelect';
import { isEmpty, isNull } from 'lodash';
import PurchaseOrderAdjustments from './PurchaseOrderAdjustments';

const EditPurchaseOrder = (props) => {
    const po_id = props.match.params.po_id;
    const { handleSubmit, register, errors, setValue, control, getValues, trigger } = useForm();
    const [isUpdated, setIsUpdated] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [adjustments, setAdjustments] = useState([]);
    const [totalAdjustedPoAmount, setTotalAdjustedPoAmount] = useState(0);

    const recalculateTotalAdjustedPo = () => {
        const values = getValues();
        const sum =  values.adjustments?.reduce((sum, adjustment) => sum + Number(adjustment.value), Number(values.original_po_amount)) ?? Number(values.original_po_amount);
        setTotalAdjustedPoAmount(sum);
        trigger('total_adjusted_po');
    }

    useEffect(() => {
        apiClient.post('getPOTrackerById', {id: Number(po_id)}).then(response => {
            const purchaseOrder = response.data;
            setInitialData(purchaseOrder);
            setTotalAdjustedPoAmount(purchaseOrder.total_adjusted_po);

            const adjustments = [
                purchaseOrder.poa_1,
                purchaseOrder.poa_2,
                purchaseOrder.poa_3,
                purchaseOrder.poa_4,
                purchaseOrder.poa_5,
                purchaseOrder.poa_6,
                purchaseOrder.poa_7,
                purchaseOrder.poa_8,
                purchaseOrder.poa_9,
                purchaseOrder.poa_10,
            ].filter(poa => poa !== null).map(poa => ({value: poa}));

            setAdjustments(adjustments);
        })
    }, [po_id, setValue])

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('id', po_id);

        for (const key in data) {
            switch (key) {
                case 'files':
                    for (const file of data[key]) {
                        formData.append('files[]', file, file.name);
                    }
                    break;
                case 'adjustments':
                    let index = 1;
                    for (const adjustment of data[key]) {
                        formData.append(`poa_${index}`, adjustment.value);
                        index++;
                    }
                    break;
                default:
                    formData.append(key, data[key]);
            }
        }

        apiClient
            .post('editPOTracker', formData)
            .then(() => {
                setIsUpdated(true);
                toast.success("Purchase order has been saved.");
            })
            .catch((error) => {
                console.error(error);
            });
    }

    if (isEmpty(initialData)) {
        return null;
    }

    return (
        <>
            <Breadcrumb title="Edit Purchase Order" parent="Commercial" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <form
                            className="needs-validation"
                            noValidate=""
                            id="create_job_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row mb-3">
                                        <FormSelect
                                            label="Client"
                                            id="client_id"
                                            name="client_id"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            options={
                                                isNull(initialData.client) ? [] : [{
                                                    value: initialData.client.id,
                                                    label: initialData.client.client_name,
                                                }]
                                            }
                                            errors={errors}
                                            value={initialData.client_id}
                                            disabled
                                        />
                                        <FormInputText
                                            id="ap_contact_name"
                                            label="AP Contact Name"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            errors={errors}
                                            register={register({required: "required"})}
                                            value={initialData.ap_contact_name}
                                        />
                                        <FormInputText
                                            id="project"
                                            label="Project"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            value={initialData.project}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-row mb-3">
                                        <FormInputText
                                            id="po_number"
                                            label="PO Number"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required"})}
                                            value={initialData.po_number}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="original_po_amount"
                                            label="Original PO amount"
                                            type="number"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            register={register({required: "required", max: {value: 99999999, message: "Max value is 99999999"}})}
                                            value={initialData.original_po_amount}
                                            onChange={recalculateTotalAdjustedPo}
                                            errors={errors}
                                        />
                                        <FormInputText
                                            id="total_adjusted_po"
                                            label="Total Adjusted PO amount"
                                            type="text"
                                            className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                                            value={totalAdjustedPoAmount}
                                            isControlled
                                            errors={errors}
                                            register={register({max: {value: 99999999, message: "Max value is 99999999"}})}
                                            disabled
                                        />
                                    </div>
                                    <div className='form-row'>
                                        <FormInputTextArea
                                            id="notes"
                                            rows={3}
                                            label="Notes"
                                            className="col-md-12 mb-12"
                                            register={register({
                                                required: false,
                                                maxLength: {
                                                    value:256,
                                                    message: "Maximum length is 256"
                                                }})}
                                            errors={errors}
                                            max={256}
                                            value={initialData.notes}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card form-row">
                                <div className="card-body col-xs-12 col-sm-6 col-md-5 col-lg-4">
                                    <PurchaseOrderAdjustments
                                        control={control}
                                        adjustments={adjustments}
                                        register={register}
                                        errors={errors}
                                        recalculateTotalAdjustedPo={recalculateTotalAdjustedPo}
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Files</h4>
                                    <div className="form-row">
                                        <FormInputFile
                                            id="files"
                                            className="col-md-12"
                                            accept="image/png,image/jpg,image/jpeg,.pdf"
                                            register={register}
                                        />
                                    </div>
                                    <FileManager
                                        fileableId={po_id}
                                        fileableType ="App\Models\POTracker"
                                        isUpdated={isUpdated}
                                        setIsUpdated={setIsUpdated}
                                    />
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditPurchaseOrder;
