import React from "react";
import {FaFileDownload} from "react-icons/fa";

const EditPurchaseOrderFilesTableColumns = ({ onDownload }) => {

    let tableColumns = [];

    tableColumns.push(
        {
            Header: 'File Name',
            accessor: 'original_name',
        },
    );

    tableColumns.push(
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <FaFileDownload color='#A51E37' title="Download" onClick={() => {
                        onDownload(row);
                    }}/>
                )
            }
        }
    );

    return tableColumns;

}

export default EditPurchaseOrderFilesTableColumns;