import { useFieldArray } from "react-hook-form";
import { FaEraser, FaPlus, FaTrash } from "react-icons/fa";
import InputInTable from "../../../forms/form-control/daily/InputInTable";
import { useEffect } from "react";

const MAX_COUNT_OF_ADJUSTMENTS = 10

const PurchaseOrderAdjustments = ({ adjustments, control, register, errors, recalculateTotalAdjustedPo }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "adjustments",
    });

    useEffect(() => {
        for (const adjustment of adjustments) {
            append(adjustment);
        }
    }, [adjustments, append, remove]);

    useEffect(() => {
        recalculateTotalAdjustedPo();
    }, [fields, recalculateTotalAdjustedPo]);

    const reachedMaxCount = fields.length >= MAX_COUNT_OF_ADJUSTMENTS;

    return (
        <>
            <div className="d-flex align-items-center gap-3 mb-2">
                <h5 className="mb-0 mr-3">Purchase Order Adjustments</h5>
                {
                    !reachedMaxCount && (
                        <FaPlus
                            color="#22af47"
                            size={20}
                            title="Add New"
                            onClick={() =>
                                append({
                                    value: 0,
                                })
                            }
                        />
                    )
                }
                <FaEraser
                    color="#A51E37"
                    className="marginLeft"
                    size={20}
                    title="Remove All"
                    onClick={() => remove()}
                />
            </div>
            <table className="table table-striped">
                    <tbody>
                        {fields.map((item, index) => (
                            <tr key={item.id}>
                                <InputInTable
                                    className="column-width-200"
                                    id={`adjustments[${index}].value`}
                                    name={`adjustments[${index}].value`}
                                    type="number"
                                    value={item.value}
                                    register={register({required: "required", max: {value: 99999999, message: "Max value is 99999999"}})}
                                    errors={errors}
                                    onChange={recalculateTotalAdjustedPo}
                                />
                                <td>
                                    <FaTrash
                                        color='#A51E37'
                                        size={20}
                                        title="Remove"
                                        onClick={() => remove(index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
            </table>
        </>
    );
};

export default PurchaseOrderAdjustments;
