export const activitiesTooltipTableColumns = [
    {
        Header: "Description",
        accessor: "description",
    },
    {
        Header: "RG",
        accessor: "revenue_group",
    },
    {
        Header: "Price",
        accessor: "price",
    },
    {
        Header: "Worked",
        accessor: "worked",
    },
    {
        Header: "Charged",
        accessor: "charged",
    },
    {
        Header: "Total",
        accessor: "total",
    },
    {
        Header: "% Unbilled",
        accessor: "unbilled",
    },
    {
        Header: "% Total Shift Hrs",
        accessor: "total_shift_hrs",
    },
];
