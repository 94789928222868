import React, { useMemo } from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import ChartContainer from './chartContainer';
import {formatNumberToDisplayWithCommas} from '../../../../../components/common/shared';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

const DrillingChart = ({chartData}) => {

    const dataset = chartData.items;
    const labels = chartData.items.map(item => item.date);
    const avgRollingDataset = chartData.items.map(item => item.avg_rolling_30_days_percentage ?? 0);
    const targetDataset = chartData.items.map(item => item.target_percentage ?? 0)

    const data = {
        labels: labels,
        datasets: [
            {
                type: 'bar',
                data: dataset,
                backgroundColor: '#bfbfbf',
                order: 2,
                label: 'Drilling %',
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'percentage'
                },
            },
            {
                type: 'line',
                data: avgRollingDataset,
                backgroundColor: '#000000',
                borderColor: '#000000',
                order: 1,
                label: 'Rolling 30-Day Avg',
            },
            {
                type: 'line',
                data: targetDataset,
                backgroundColor: '#c00000',
                borderColor: '#c00000',
                order: 0,
                label: 'Target',
            }
        ],
    }

    const options = {
        scales: {
            y: {
                title: 'Drilling %',
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maxBarThickness: 20,
    }

    const summaryItems = useMemo(() => [
        {
            label: 'Monthly Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.monthly_avg)}%`,
        },
        {
            label: 'Rolling 30-Day Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.rolling_30_days_avg)}%`,
        },
        {
            label: 'Current Target',
            value: `${formatNumberToDisplayWithCommas(chartData.current_target)}%`,
        },
    ], [chartData.rolling_30_days_avg, chartData.monthly_avg, chartData.current_target]);

    return (
        <ChartContainer title="Drilling %" summaryItems={summaryItems}>
            <Bar data={data} options={options}/>
        </ChartContainer>
    )
}

export default DrillingChart;
