import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";

/**
 * Filter component for Operational Dashboard report
 * @param prop
 * @returns {JSX.Element}
 * @constructor
 */
const OperationalDashboardFilters = (prop) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({});
    const {
        setReportFilters,
        setDashboardData,
    } = prop;

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getOperationalDashboardData', ({
                discipline_id: data.discipline_id !== '' ? data.discipline_id : null,
                financial_period_id: data.financial_period_id,
            }))
            .then(response => {
                if (isEmpty(response.data)) {
                    setDashboardData(null);
                    return;
                }
                setDashboardData(response.data);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of financial periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => {
                            if (is_current) {
                                setCurrentPeriod({id: id, name: name + ' (Current)'})
                            }
                            return {
                                id: id, name: name + (is_current ? ' (Current)' : '')
                            }
                        }
                    )
                );
        });
    }, []);

    useEffect(() => {
        apiClient
        .get('getDisciplines')
        .then(response => {
            setDisciplines(response.data.disciplines);
        });
    }, []);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            setValue('financial_period_id', currentPeriod.id);
        }, 200);
    }, [currentPeriod, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row row s-gy">
                <FormSelect
                    label="Discipline"
                    id="discipline_id"
                    name="discipline_id"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={disciplines}
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Financial Period"
                    className="col-md-4"
                    options={financialPeriods}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default OperationalDashboardFilters;
